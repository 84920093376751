import { Component } from 'rxcomp';
import { FormControl, FormGroup, Validators } from 'rxcomp-form';
import { first, takeUntil } from 'rxjs/operators';
import { GtmService } from '../../common/gtm/gtm.service';
import { IRNewsletterService } from './ir-newsletter.service';

export class IRNewsletterComponent extends Component {

  onInit() {
    this.error = null;
    this.success = false;
    this.response = null;
    this.message = null;
    const form = this.form = new FormGroup({
      firstName: new FormControl(null, [Validators.RequiredValidator()]),
      lastName: new FormControl(null, [Validators.RequiredValidator()]),
      email: new FormControl(null, [Validators.RequiredValidator(), Validators.EmailValidator()]),
			language: new FormControl(null, [Validators.RequiredValidator()]),
      /*
      sec: new FormControl(null),
      insiderTransaction: new FormControl(null),
      reports: new FormControl(null),
      */
      privacy: new FormControl(null, [Validators.RequiredTrueValidator()]),
      checkRequest: window.antiforgery,
      checkField: '',
      action: 'save_ir-newsletter',
    });
    this.controls = form.controls;
    form.changes$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.pushChanges();
    });
  }

  test() {
    const form = this.form;
    form.patch({
      firstName: 'Jhon',
      lastName: 'Appleseed',
      email: 'jhonappleseed@gmail.com',
      /*
      sec: true,
      insiderTransaction: false,
      reports: true,
      */
      checkRequest: window.antiforgery,
      checkField: ''
    });
  }

  reset() {
    const form = this.form;
    form.reset();
  }

  onSubmit() {
    const form = this.form;
    // console.log('IRNewsletterComponent.onSubmit', form.value);
    if (form.valid) {
      // console.log('IRNewsletterComponent.onSubmit', form.value);
      form.submitted = true;
      IRNewsletterService.submit$(form.value).pipe(
        first(),
      ).subscribe(_ => {
        if (_.success) {
          GtmService.push({ 'event': 'IRNewsletter', 'form_name': 'IRNewsletter' });
        }
        this.success = true;
        form.reset();
        this.response = _.data['response'];
        this.message = _.data['message'];
        // if (form.value.newsletter) {
        //   GtmService.push({
        //     'event': 'IRNewsletter',
        //     'form_name': 'IRNewsletter'
        //   });
        // }
      }, error => {
        console.log('IRNewsletterComponent.error', error);
        this.error = error;
        this.pushChanges();
      });
    } else {
      form.touched = true;
    }
  }

}

IRNewsletterComponent.meta = {
  selector: '[ir-newsletter]',
};
