import VanillaCalendar from '@uvarov.frontend/vanilla-calendar';
import { Component, getContext } from 'rxcomp';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ModalService } from '../../common/modal/modal.service';
import { environment } from '../../environment';
import { IRCalendarService } from './ir-calendar.service';

export class IRCalendarComponent extends Component {

  onInit() {
    const now = new Date();
    this.year = now.getFullYear();
    this.month = now.getMonth();
    this.items = [];
    this.upcomingItems = [];
    // this.visibleItems = [];
    this.legendItems = [];
    this.load$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(data => {
      this.items = data[0];
      this.setLegendItems();
      this.filterItems();
      this.initCalendar(data[0]);
    });
  }

  setLegendItems() {
    const items = this.items;
    const legendItems = [];
    items.forEach(item => {
      if (!legendItems.find(x => x.id === item.category.id)) {
        legendItems.push({ ...item.category });
      }
    });
    this.legendItems = legendItems;
  }

  filterItems() {
    const today = new Date();
    const items = this.items;
    this.upcomingItems = items.filter(x => {
      const date = x.date;
      return date.getTime() > today.getTime();
    });
    this.upcomingItems.length = Math.min(this.upcomingItems.length, 10);
    /*
    this.visibleItems = items.filter(x => {
      const date = x.date;
      return date.getFullYear() === this.year && date.getMonth() === this.month;
    });
    */
    this.pushChanges();
  }

  initCalendar(items) {
    const popups = {};
    // console.log(items);
    items.forEach(item => {
      const date = item.date;
      const key = `${date.getFullYear()}-${padded(date.getMonth() + 1)}-${padded(date.getDate())}`;
      popups[key] = {
        modifier: `irc-${item.category.key}`,
        // html: '<div>Meeting at 9:00 PM</div>',
      };
    });
    // console.log(popups);
    const { node } = getContext(this);
    const target = node.querySelector('.ir-calendar__target') || node;
    const calendar = new VanillaCalendar(target, {
      type: 'default',
      months: 1,
      jumpMonths: 1,
      actions: {
        clickDay: (event, dates) => {
          // console.log('IRCalendarComponent', dates);
          this.openModal(dates);
        },
        clickArrow: (event, year, month) => {
          // console.log('IRCalendarComponent', year, month);
          this.year = year;
          this.month = month;
          this.filterItems();
        },
      },
      settings: {
        lang: environment.currentLanguage,
        visibility: {
          weekend: false,
          today: false,
          theme: 'light',
        },
      },
      popups,
    });
    calendar.init();
    // console.log(calendar);
  }

  load$() {
    return combineLatest([
      IRCalendarService.all$().pipe(
        map(items => {
          items.forEach(x => x.date = new Date(x.date));
          items.sort((a, b) => {
            return a.date.getTime() - b.date.getTime();
          });
          return items;
        })
      ),
    ]);
  }

  openModal(keys) {
    const items = this.items;
    const item = items.find(item => {
      const date = item.date;
      const key = `${date.getFullYear()}-${padded(date.getMonth() + 1)}-${padded(date.getDate())}`;
      return keys.includes(key);
    });
    if (item) {
      ModalService.open$({ src: environment.template.modal.calendarModal, data: item }).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        // console.log('IRCalendarComponent.calendarModal', event);
      });
    }
  }

}

IRCalendarComponent.meta = {
  selector: '[ir-calendar]',
  inputs: ['active'],
};

function padded(num, size = 2) {
  var s = '000000000' + num;
  return s.substring(s.length - size);
}
