import { combineLatest } from 'rxjs';
import { FiltersComponent } from '../filters/filters.component';
import { NewsService } from './news.service';

export class NewsComponent extends FiltersComponent {

  onInit() {
    super.onInit();
    this.categoryId = this.categoryId || null;
  }

  load$() {
    return combineLatest([
      NewsService.getItems(this),
      NewsService.getFilters(this),
      // NewsService.all$(),
      // NewsService.filters$(),
    ]);
  }

  doFilterItem(key, item, value) {
    switch (key) {
      case 'category':
        return item.categories.indexOf(value) !== -1;
      case 'search':
        return item.title.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      default:
        return false;
    }
  }

  doSortItems(items) {
    const shouldShowEvidence = this.shouldShowEvidence();
    return items.filter(x => !shouldShowEvidence || !x.evidence);
  }

  clearCategory() {
    this.filters.category.clear();
  }

  setCategory(categoryId) {
    this.filters.category.set({ value: categoryId });
  }

  isCategoryActive(categoryId) {
    return this.filters.category.has({ value: categoryId });
  }

  isCategoryEmpty() {
    return !this.filters.category.hasAny();
  }

  setFiltersParams() {
    if (this.categoryId) {
      this.filters.category.set({ value: this.categoryId });
    }
  }

  setPage(page) {
    window.scrollTo(0, 0);
    return super.setPage(page);
  }

  shouldShowEvidence() {
    return this.pagination.page === 1 && !this.filters.category.hasAny();
  }
}

NewsComponent.meta = {
  selector: '[news]',
  inputs: ['categoryId'],
};
