import { Directive, getContext } from 'rxcomp';
import { takeUntil } from 'rxjs/operators';
import Splitting from 'splitting';
import { IntersectionService } from '../intersection/intersection.service';

export class SplitDirective extends Directive {

  onInit() {
    const { node } = getContext(this);
    const results = Splitting({ target: node, by: 'lines' });
    node.classList.add('split');
  }

  onChanges() {
    if (!this.splitted) {
      this.splitted = true;
      const { node } = getContext(this);
      IntersectionService.intersecting$(node).pipe(
        // first(),
        takeUntil(this.unsubscribe$),
      ).subscribe(entry => {
        node.classList.toggle('splitted', entry.isIntersecting);
      });
    }
  }

}

SplitDirective.meta = {
  selector: '[split]',
};
