import { getContext } from 'rxcomp';
import { ControlComponent } from './control.component';

export class ControlFileComponent extends ControlComponent {

  onInit() {
    super.onInit();
    this.labels = window.labels || {};
    this.placeholder = this.placeholder || this.label;
    this.file = null;
    this.onReaderComplete = this.onReaderComplete.bind(this);
  }

  onChanges(changes) {
    super.onChanges(changes);
    const { node } = getContext(this);
    const input = node.querySelector('input');
    if (input) {
      if (this.accept) {
        input.setAttribute('accept', this.accept);
      } else {
        input.removeAttribute('accept');
      }
    }
    // console.log('ControlFileComponent.onChanges', this.control.invalid);
  }

  onInputDidChange(event) {
    const input = event.target;
    const file = input.files[0];
    this.file = {
      name: file.name,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      size: file.size,
      type: file.type,
    };
    const reader = new FileReader();
    reader.addEventListener('load', this.onReaderComplete);
    reader.readAsDataURL(file);
    // reader.readAsArrayBuffer() // Starts reading the contents of the specified Blob, once finished, the result attribute contains an ArrayBuffer representing the file's data.
    // reader.readAsBinaryString() // Starts reading the contents of the specified Blob, once finished, the result attribute contains the raw binary data from the file as a string.
    // reader.readAsDataURL() // Starts reading the contents of the specified Blob, once finished, the result attribute contains a data: URL representing the file's data.
    // reader.readAsText() // Starts reading the contents of the specified Blob, once finished, the result attribute contains the contents of the file as a text string. An optional encoding name can be specified.
  }

  onReaderComplete(event) {
    const content = event.target.result;
    this.file.content = content;
    this.control.value = this.file;
    // console.log('ControlFileComponent.onReaderComplete', this.file);
    // image/*,
  }

}

ControlFileComponent.meta = {
  selector: '[control-file]',
  inputs: ['control', 'label', 'placeholder', 'disabled', 'accept', 'info'],
  template: /* html */ `
  	<div class="control-file__group" [class]="{ required: control.invalid }">
      <label [labelFor]="uniqueId" *if="label">
        <span [innerHTML]="label"></span> <span class="required__sign">*</span>
      </label>
      <span class="required__badge" [innerHTML]="'required' | label"></span>
			<button class="btn--upload">
        <span [innerHTML]="placeholder || label"></span>
        <svg class="upload"><use xlink:href="#upload"></use></svg>
      </button>
			<input class="control-file__input" type="file" [id]="uniqueId" [name]="control.name" class="control--file" [placeholder]="placeholder" [disabled]="disabled" (change)="onInputDidChange($event)" />
      <span class="control-file__info" [innerHTML]="info" *if="info"></span>
		</div>
		<errors-component [control]="control"></errors-component>
	`
};
