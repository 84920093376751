/* global ScrollTimeline, ViewTimeline, CSSUnitValue */
import { Directive, getContext } from 'rxcomp';
import { fromEvent } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';

export class HeroIndexBackgroundDirective extends Directive {

  onInit() {
    this.resize$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  enabled_ = false;
  set enabled(enabled) {
    if (this.enabled_ !== enabled) {
      this.enabled_ = enabled;
      if (this.animation_) {
        this.animation_.cancel();
      }
      // console.log('HeroIndexBackgroundDirective', enabled);
      if (enabled) {
        const { node } = getContext(this);
        this.animation_ = node.animate(
          [
            {
              transform: 'scale(0.8) translateY(-100vh)',
              offset: 0,
            }, {
              transform: 'scale(1) translateY(-50vh)',
              offset: 0.4,
            }, {
              transform: 'scale(1) translateY(0px)',
              offset: 1,
            }
          ],
          {
            fill: 'both',
            timeline: new ViewTimeline({
              subject: node.parentElement
            }),
            rangeStart: { rangeName: 'contain', offset: CSS.percent(0) },
            rangeEnd: { rangeName: 'contain', offset: CSS.percent(100) },
          });
      }
    }
  }

  resize$() {
    return fromEvent(window, 'resize').pipe(
      map(() => window.innerWidth),
      startWith(window.innerWidth),
      tap(width => {
        this.enabled = (width >= 1024);
      }),
    );
  }

}

HeroIndexBackgroundDirective.meta = {
  selector: '.hero-index__background',
};
