
export const environmentServed = {
  flags: {
    production: true,
    cart: true,
  },
  markets: ['IT', 'EU', 'AM', 'AS', 'IN'],
  defaultMarket: 'IT',
  currentMarket: 'IT',
  userCountry: 'IT',
  userMarket: 'IT',
  languages: ['it', 'en', 'de', 'ch'],
  defaultLanguage: 'it',
  currentLanguage: 'it',
  detectedCountry: 'Italia',
  api: '/api',
  assets: '/Client/docs/',
  slug: {
  },
  template: {
    modal: {
      genericModal: '/wp-content/themes/dexelance/client/docs/partials/modals/generic-modal.html',
      weChatModal: '/wp-content/themes/dexelance/client/docs/partials/modals/we-chat-modal.html',
      calendarModal: '/wp-content/themes/dexelance/client/docs/partials/modals/calendar-modal.html',
    }
  },
  facebook: {
    appId: 0,
    fields: 'id,name,first_name,last_name,email,gender,picture,cover,link',
    scope: 'public_profile, email', // publish_stream
    tokenClient: '',
    version: 'v11.0',
  },
  google: {
    clientId: '',
  },
  linkedIn: {
    clientId: '',
    clientSecret: '',
    scope: 'r_emailaddress r_liteprofile',
  },
  googleMaps: {
    apiKey: '',
  },
  thron: {
    clientId: '',
  },
  workers: {
    image: '/Client/docs/js/workers/image.service.worker.js',
    prefetch: '/Client/docs/js/workers/prefetch.service.worker.js',
  },
  githubDocs: 'https://raw.githubusercontent.com/actarian/dexelance/main/docs/',
};
