import { Component, getContext } from 'rxcomp';
import { takeUntil } from 'rxjs/operators';
import { GtmService } from './common/gtm/gtm.service';
import { ModalService } from './common/modal/modal.service';
import { HeaderService } from './components/header/header.service';
import { environment } from './environment';

export default class AppComponent extends Component {

  set header(header) {
    if (this.header_ !== header) {
      this.header_ = header;
      const { node } = getContext(this);
      node.setAttribute('class', `app ${header ? `app--${header}` : ''}`);
    }
  }

  onInit() {
    const { node } = getContext(this);
    node.classList.remove('hidden');
    this.header = HeaderService.currentHeader;
    HeaderService.header$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(header => {
      this.header = header;
      if (window.lenis) {
        if (header == 'menu') {
          window.lenis.stop();
        } else {
          window.lenis.start();
        }
      }
      this.pushChanges();
    });
    // console.log(environment);
  }

  onWeChat(event) {
    console.log('AppComponent.onWeChat', event);
    ModalService.open$({ src: environment.template.modal.weChatModal }).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      // console.log('AppComponent.onWeChat.weChatModal', event);
    });
    GtmService.push({
      'event': 'WeChat',
    });
  }

  // onView() { const context = getContext(this); }

  // onChanges() {}

  // onDestroy() {}
}

AppComponent.meta = {
  selector: '[app]',
};
