import { Directive, getContext } from 'rxcomp';
import { takeUntil } from 'rxjs/operators';
import { CursorService } from './cursor.service';

export class CursorTypeComponent extends Directive {

  onInit() {
    const { node } = getContext(this);
    this.checkAttribute();
    CursorService.registerNode$(node).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe();
    // console.log('CursorTypeComponent.onInit', this.node.getAttribute('cursor-type'));
  }

  checkAttribute() {
    const { node } = getContext(this);
    if (!node.hasAttribute('cursor-type')) {
      const nodeName = node.nodeName.toLowerCase();
      if (nodeName === 'a' || nodeName === 'button') {
        node.setAttribute('cursor-type', 'nav');
      }
    }
  }

}

CursorTypeComponent.meta = {
  selector: '[cursor-type], a, button',
};
