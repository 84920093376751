import { Component, getContext } from 'rxcomp';
import { fromEvent } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export class AreaPropositionComponent extends Component {

  onInit() {
    const { node } = getContext(this);
    const backgrounds = Array.from(node.querySelectorAll('.area-proposition__background img'));
    if (backgrounds.length) {
      backgrounds[0].classList.add('active');
    }
    const navs = Array.from(node.querySelectorAll('.nav--area a'));
    fromEvent(navs, 'mouseover').pipe(
      tap((event) => {
        const index = navs.indexOf(event.currentTarget);
        backgrounds.forEach((background, i) => {
          background.classList.toggle('active', index === i);
        });
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

}

AreaPropositionComponent.meta = {
  selector: '[area-proposition]',
};
