import { Component, getContext } from 'rxcomp';
import { fromEvent, merge, of, Subject, timer } from 'rxjs';
import { debounce, filter, finalize, map, startWith, takeUntil, tap } from 'rxjs/operators';

export class OverscrollDirective extends Component {

  set swiper(swiper) {
    if (this.swiper_ !== swiper) {
      this.swiper_ = swiper;
      if (swiper) {
        const { node } = getContext(this);
        const inner = this.inner;
        swiper.progress$.pipe(
          // debounceTime(500),
          debounce(progress => progress > 0 && progress < 1 ? timer(500) : of(progress)),
          tap(progress => {
            const percent = Math.max(0, Math.min(1, progress));
            if (this.percent_ !== percent) {
              this.percent_ = percent;
              const rect = node.getBoundingClientRect();
              if (rect.top < window.innerHeight && rect.bottom > 0) {
                const innerRect = inner.getBoundingClientRect();
                const scrollHeight = percent * (rect.height - innerRect.height);
                // if (scrollHeight > window.innerHeight / 2) {
                const y = window.pageYOffset + rect.top + scrollHeight;
                // console.log(scrollHeight, y);
                window.scrollTo(0, y);
                // }
              }
            }
          }),
          takeUntil(this.unsubscribe$)
        ).subscribe();
      }
    }
  }

  onInit() {
    const { node } = getContext(this);
    const inner = this.inner = node.querySelector('.overscroll__inner');
    const targetSelector = node.getAttribute('overscroll');
    const target = this.target = node.querySelector(targetSelector);
    // console.log('OverscrollDirective', target, targetSelector);
    if (target) {
      this.swiper = target.swiper;
      // console.log(target, target.swiper);
      this.listeners$().pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe();
    }
  }

  listeners$() {
    return merge(
      this.resize$(),
      this.scroll$(),
    );
  }

  resizeObserver$() {
    const inner = this.inner;
    const target = this.target;
    const subject = new Subject();
    const observer = new ResizeObserver(entries => {
      subject.next(entries);
    });
    observer.observe(target);
    return subject.pipe(
      map(entries => entries.find(entry => entry.target === target)),
      // tap(entry => console.log('ResizeObserver', entry)),
      filter(entry => entry !== undefined),
      finalize(_ => observer.unobserve(target)),
    );
  }

  resize$() {
    const inner = this.inner;
    const target = this.target;
    const header = document.querySelector('.header');
    return merge(
      fromEvent(window, 'resize'),
      this.resizeObserver$(),
    ).pipe(
      startWith(true),
      tap(_ => {
        // const rect = node.getBoundingClientRect();
        /*
        const innerRect = inner.getBoundingClientRect();
        const dy = Math.max(header.offsetHeight, (window.innerHeight - innerRect.height) / 2);
        inner.style.top = `${dy}px`;
        */
        // console.log('resize');
        this.swiper = target.swiper;
      })
    );
  }

  scroll$() {
    const { node } = getContext(this);
    const inner = this.inner;
    const target = this.target;
    // const log = document.querySelector('.log');
    return fromEvent(window, 'scroll').pipe(
      tap(_ => {
        const rect = node.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          const innerRect = inner.getBoundingClientRect();
          // const dy = Math.max(0, (window.innerHeight - innerRect.height) / 2);
          // let y = - Math.min(rect.top - dy, 0);
          let y = -rect.top;
          const percent = y / (rect.height - innerRect.height);
          if (target.swiper) {
            target.swiper.setPercent(percent);
            // this.swiper = target.swiper;
          }
        }
      }),
    );
  }
}

OverscrollDirective.meta = {
  selector: '[overscroll]',
};
