/* global ScrollTimeline, ViewTimeline, CSSUnitValue */
import { Directive, getContext } from 'rxcomp';
import { fromEvent } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';

export class ParallaxDirective extends Directive {

  onInit() {
    this.resize$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  enabled_ = false;
  set enabled(enabled) {
    if (this.enabled_ !== enabled) {
      this.enabled_ = enabled;
      if (this.animation_) {
        this.animation_.cancel();
      }
      // console.log('ParallaxDirective', enabled);
      if (enabled) {
        const { node } = getContext(this);
        const container = node.parentElement;
        this.animation_ = node.animate(
          [
            {
              transform: 'translate(0, 60px)',
              opacity: 0,
              offset: 0,
            }, {
              opacity: 1,
              offset: 0.25,
            }, {
              transform: 'translate(0, -60px)',
              opacity: 1,
              offset: 1,
            }
          ],
          {
            fill: 'both',
            timeline: new ViewTimeline({
              subject: container
            }),
            rangeStart: { rangeName: 'cover', offset: CSS.percent(0) },
            rangeEnd: { rangeName: 'cover', offset: CSS.percent(100) },
          });
      }
    }
  }

  resize$() {
    return fromEvent(window, 'resize').pipe(
      map(() => window.innerWidth),
      startWith(window.innerWidth),
      tap(width => {
        this.enabled = (width >= 1024);
      }),
    );
  }
}

ParallaxDirective.meta = {
  selector: '[parallax]',
};
