import { Module } from 'rxcomp';
import { ErrorComponent } from '../components/error/error.component';
import { AreaPropositionComponent } from './area-proposition/area-proposition.component';
import { BrandComponent } from './brand/brand.component';
import { ContactComponent } from './contact/contact.component';
import { CursorTypeComponent } from './cursor/cursor-type.component';
import { CursorComponent } from './cursor/cursor.component';
import { DataImageDirective } from './data-image/data-image.directive';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { HeaderComponent } from './header/header.component';
import { HeroIndexBackgroundDirective } from './hero-index-background/hero-index-background.directive';
import { IPOComponent } from './ipo/ipo.component';
import { IRCalendarComponent } from './ir-calendar/ir-calendar.component';
import { IRMenuComponent } from './ir-menu/ir-menu.component';
import { IRNewsletterComponent } from './ir-newsletter/ir-newsletter.component';
import { MenuDirective } from './menu/menu.directive';
import { NewsComponent } from './news/news.component';
import { PlayerComponent } from './player/player.component';
import { SwiperBrandPropositionDirective } from './swiper-brand-proposition/swiper-brand-proposition.directive';
import { SwiperBrandRelatedDirective } from './swiper-brand-related/swiper-brand-related.directive';
import { SwiperGalleryDirective } from './swiper-gallery/swiper-gallery.directive';
import { SwiperGovernanceRelatedDirective } from './swiper-governance-related/swiper-governance-related.directive';
import { SwiperHistoryDirective } from './swiper-history/swiper-history.directive';
import { SwiperNewsPropositionDirective } from './swiper-news-proposition/swiper-news-proposition.directive';
import { SwiperTimelineDirective } from './swiper-timeline/swiper-timeline.directive';

const factories = [
  AreaPropositionComponent,
  BrandComponent,
  ContactComponent,
  IPOComponent,
  CursorComponent,
  CursorTypeComponent,
  DataImageDirective,
  ErrorComponent,
  GenericModalComponent,
  HeaderComponent,
  HeroIndexBackgroundDirective,
  IRCalendarComponent,
  IRMenuComponent,
  IRNewsletterComponent,
  MenuDirective,
  NewsComponent,
  PlayerComponent,
  SwiperBrandPropositionDirective,
  SwiperBrandRelatedDirective,
  SwiperGalleryDirective,
  SwiperGovernanceRelatedDirective,
  SwiperHistoryDirective,
  SwiperNewsPropositionDirective,
  SwiperTimelineDirective,
];

const pipes = [
];

export class ComponentsModule extends Module { }

ComponentsModule.meta = {
  imports: [
  ],
  declarations: [
    ...factories,
    ...pipes,
  ],
  exports: [
    ...factories,
    ...pipes,
  ],
};
