import { Component } from 'rxcomp';
import { takeUntil } from 'rxjs/operators';
import { MenuService } from '../menu/menu.service';
import { HeaderService } from './header.service';

export class HeaderComponent extends Component {

  onInit() {
    // console.log('HeaderComponent.onInit');
    // const body = document.querySelector('body');
    this.header = HeaderService.currentHeader;
    HeaderService.header$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(header => {
      this.header = header;
      this.pushChanges();
      // body.setAttribute('class', header !== -1 ? `${header}-active` : '');
    });
    this.menu = MenuService.currentMenu;
    MenuService.menu$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(menu => {
      this.menu = menu;
      this.pushChanges();
    });
  }

  onToggle(id) {
    MenuService.onBack();
    HeaderService.toggleHeader(id);
  }
}

HeaderComponent.meta = {
  selector: 'header',
};
