import { Component } from 'rxcomp';
import { takeUntil } from 'rxjs/operators';
import { HeaderService } from '../header/header.service';
import { MenuService } from '../menu/menu.service';

export class IRMenuComponent extends Component {

  onInit() {
    // console.log('IRMenuComponent.onInit');
    this.header = HeaderService.currentHeader;
    HeaderService.header$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(header => {
      this.header = header;
      this.pushChanges();
    });
    if (this.active && window.innerWidth >= 1440) {
      HeaderService.setHeader('ir-menu');
    }
    MenuService.menu$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(menu => {
      this.menu = menu;
      this.pushChanges();
    });
  }

  onToggle(id) {
    MenuService.onBack();
    HeaderService.toggleHeader(id);
  }
}

IRMenuComponent.meta = {
  selector: '[ir-menu]',
  inputs: ['active'],
};
