import { Component, getContext } from 'rxcomp';
import { FormControl, FormGroup, Validators } from 'rxcomp-form';
import { first, takeUntil, tap } from 'rxjs/operators';
// import { GtmService } from '../../common/gtm/gtm.service';
import { FormService } from '../../controls/form.service';
import RequiredIPOFieldValidator from '../../controls/required_ipo_field.validator';
import { environment } from '../../environment';
import { IPOService } from './ipo.service';

export class IPOComponent extends Component {

  onInit() {
    this.error = null;
    this.success = false;
    this.response = null;
    this.message = null;
    const form = this.form = new FormGroup({
      res_country: new FormControl(null, [Validators.RequiredValidator(), RequiredIPOFieldValidator('res_country', getDataGroup)]),
      dom_country: new FormControl(null, [Validators.RequiredValidator(), RequiredIPOFieldValidator('dom_country', getDataGroup)]),
      area_code: new FormControl(null, [Validators.RequiredValidator(), RequiredIPOFieldValidator('area_code', getDataGroup)]),
      disclaimer: new FormControl(null, [Validators.RequiredTrueValidator()]),
      privacy: new FormControl(null, [Validators.RequiredTrueValidator()]),
      checkRequest: window.antiforgery,
      checkField: '',
      action: 'save_contact',
    });
    this.controls = form.controls;
    function getDataGroup() {
      // console.log(form.controls.data);
      return form;
    }
    form.changes$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.pushChanges();
    });
    this.load$().pipe(
      first(),
    ).subscribe();
  }

  load$() {
    return IPOService.data$().pipe(
      tap(data => {
        const controls = this.controls;
        controls.res_country.options = FormService.toSelectOptions(data.res_country.options);
        controls.dom_country.options = FormService.toSelectOptions(data.doc_country.options);
        controls.area_code.options = FormService.toSelectOptions(data.area_code.options);
        this.pushChanges();
      })
    );
  }

  // test() {
  //   const form = this.form;
  //   // const controls = this.controls;
  //   // const country = controls.country.options.length > 1 ? controls.country.options[1].id : null;
  //   // const occupation = controls.occupation.options.length > 1 ? controls.occupation.options[1].id : null;
  //   form.patch({
  //     firstName: 'Jhon',
  //     lastName: 'Appleseed',
  //     email: 'jhonappleseed@gmail.com',
  //     // country: country,
  //     // occupation: occupation,
  //     message: 'Hi!',
  //     privacy: true,
  //     checkRequest: window.antiforgery,
  //     checkField: ''
  //   });
  // }

  reset() {
    const form = this.form;
    form.reset();
  }

  onSubmit(event) {
    const form = this.form;
    // console.log('ContactComponent.onSubmit', form.value);
    // console.log('ContactComponent.onSubmit', 'form.valid', valid);
    if (form.valid) {
      // console.log('ContactComponent.onSubmit', form.value);
      // document.getElementById("form-disclaimer").submit();
      form.submitted = true;
      form.reset();
      this.reSubmit();
      // IPOService.submit$(form.value).pipe(
      //   first(),
      // ).subscribe(_ => {
      //   if (_.success) {
      //     GtmService.push({ 'event': 'Contact', 'form_name': 'Contatti' });
      //   }
      //   this.success = true;
      //   form.reset();
      //   this.response = _.data['response'];
      //   this.message = _.data['message'];
      //   if (form.value.newsletter) {
      //     GtmService.push({
      //       'event': 'ContactNewsletter',
      //       'form_name': 'ContattiNewsletter'
      //     });
      //   }
      // }, error => {
      //   console.log('ContactComponent.error', error);
      //   this.error = error;
      //   this.pushChanges();
      // });
    } else {
      form.touched = true;
      event.preventDefault();
    }
  }

  reSubmit() {
    const { node } = getContext(this);
    const form = node.querySelector('form');
    if (form) {
      if (!environment.flags.production) {
        form.method = 'GET';
      }
      form.submit();
    }
  }

  // onOccupationDidChange() {
  //   console.log('ContactComponent.onOccupationDidChange');
  //   this.controls.cv.switchValidators_();
  // }

}

IPOComponent.meta = {
  selector: '[ipo]',
};
