import { Module } from 'rxcomp';
import { AccordionDirective } from './accordion/accordion.directive';
import { AltDirective } from './alt/alt.directive';
import { CalendarBtnDirective } from './calendar-btn/calendar-btn.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { DatePipe } from './date/date.pipe';
import { DropdownItemDirective } from './dropdown/dropdown-item.directive';
import { DropdownDirective } from './dropdown/dropdown.directive';
import { EnterDirective } from './enter/enter.directive';
import { EnvPipe } from './env/env.pipe';
import { FlagPipe } from './flag/flag.pipe';
import { HighlightPipe } from './highlight/highlight.pipe';
import { HtmlPipe } from './html/html.pipe';
import { IdDirective } from './id/id.directive';
import { LabelForDirective } from './label-for/label-for.directive';
import { LabelPipe } from './label/label.pipe';
import { LenisDirective } from './lenis/lenis.directive';
import { ModalOutletComponent } from './modal/modal-outlet.component';
import { NameDirective } from './name/name.directive';
import { NumberPipe } from './number/number.pipe';
import { OverscrollDirective } from './overscroll/overscroll.directive';
import { ParallaxDirective } from './parallax/parallax.directive';
import { ReelDirective } from './reel/reel.directive';
import { RelativeDatePipe } from './relative-date/relative-date.pipe';
import { SlugPipe } from './slug/slug.pipe';
import { SplitDirective } from './split/split.directive';
import { SvgIconStructure } from './svg/svg-icon.structure';
import { SwiperDirective } from './swiper/swiper.directive';
import { TitleDirective } from './title/title.directive';

const factories = [
  AccordionDirective,
  AltDirective,
  CalendarBtnDirective,
  ClickOutsideDirective,
  DropdownDirective,
  DropdownItemDirective,
  EnterDirective,
  IdDirective,
  LabelForDirective,
  LenisDirective,
  ModalOutletComponent,
  NameDirective,
  OverscrollDirective,
  ParallaxDirective,
  ReelDirective,
  SplitDirective,
  SvgIconStructure,
  SwiperDirective,
  TitleDirective,
];

const pipes = [
  DatePipe,
  EnvPipe,
  FlagPipe,
  HighlightPipe,
  HtmlPipe,
  LabelPipe,
  NumberPipe,
  RelativeDatePipe,
  SlugPipe,
];

export class CommonModule extends Module { }

CommonModule.meta = {
  imports: [
  ],
  declarations: [
    ...factories,
    ...pipes,
  ],
  exports: [
    ...factories,
    ...pipes,
  ],
};
