import { Component } from 'rxcomp';

export class TestComponent extends Component {

  onTest(event) {
    this.test.next(event);
  }

  onReset(event) {
    this.reset.next(event);
  }

}

TestComponent.meta = {
  selector: 'test-component',
  inputs: ['form'],
  outputs: ['test', 'reset'],
  template: /* html */ `
	<div class="test-component" *if="'test' | flag || !('production' | flag)">
		<div class="test-component__title">Development mode</div>
		<code [innerHTML]="form.value | json"></code>
    <div class="test-component__cta">
      <button type="button" class="btn--secondary" (click)="onReset($event)"><span>Reset</span></button>
      <button type="button" class="btn--secondary" (click)="onTest($event)"><span>Test</span></button>
    </div>
	</div>
	`
};
