import { ApiService } from '../../common/api/api.service';
import { environment } from '../../environment';

export class IRNewsletterService {

  static submit$(payload) {
    if (environment.flags.production) {
      return ApiService.http$('POST', window.ajaxurl, payload, 'application/x-www-form-urlencoded');
    } else {
      return ApiService.get$('/ir-newsletter/submit.json');
    }
  }

}
