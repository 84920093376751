import { Component, getContext } from 'rxcomp';
import { ModalOutletComponent } from '../../common/modal/modal-outlet.component';
import { ModalService } from '../../common/modal/modal.service';

export class GenericModalComponent extends Component {

  onInit() {
    super.onInit();
    const { parentInstance } = getContext(this);
    this.data = parentInstance instanceof ModalOutletComponent ? parentInstance.modal.data : {};
  }

  onClose() {
    ModalService.reject();
  }
}

GenericModalComponent.meta = {
  selector: '[generic-modal]'
};
