/* global gsap, Power2 */
import { Component, getContext } from 'rxcomp';
import { fromEvent, fromEventPattern, merge } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SessionStorageService } from '../../common/storage/session-storage.service';

export class PlayerComponent extends Component {

  onInit() {
    const { node } = getContext(this);
    node.classList.add('player');
    this.labelPlay = this.labelPlay || 'View';
    this.labelClose = this.labelClose || 'Close';
    this.video = node.querySelector('video');
    this.video.setAttribute('src', this.src);
    this.video.setAttribute('cover', this.cover);
    this.listeners$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  listeners$() {
    return merge(
      this.opened$(),
      this.active$(),
      this.toggle$(),
      this.muted$(),
      this.track$(),
      this.event$(),
      this.fullscreen$(),
      this.fullscreenChange$(),
    );
  }

  opened$() {
    const { node } = getContext(this);
    const togglers = node.querySelectorAll('.btn--cta, .btn--close');
    return fromEvent(togglers, 'click').pipe(
      tap(() => {
        /*
        if (!this.opened && !this.fullscreen) {
          this.toggleFullScreen();
        }
        */
        this.opened = !this.opened;
      }),
    );
  }

  active$() {
    const { node } = getContext(this);
    return fromEvent(node, 'mousemove').pipe(
      tap(() => {
        this.setActive();
      }),
    );
  }

  toggle$() {
    const { node } = getContext(this);
    const togglers = node.querySelectorAll('.btn--play, .btn--pause');
    return fromEvent(togglers, 'click').pipe(
      tap(event => {
        event.stopPropagation();
        this.togglePlay();
      }),
    );
  }

  muted$() {
    const { node } = getContext(this);
    const buttonAudio = node.querySelector('.btn--audio');
    return fromEvent(buttonAudio, 'click').pipe(
      tap(() => {
        this.muted = !this.muted;
      }),
    );
  }

  track$() {
    const { node } = getContext(this);
    const track = node.querySelector('.player__track');
    return fromEvent(track, 'click').pipe(
      tap(event => {
        const rect = track.getBoundingClientRect();
        const pow = Math.max(0, Math.min(1, (event.clientX - rect.left) / rect.width));
        const duration = this.duration;
        const currentTime = pow * duration;
        this.setCurrentTime(currentTime);
      }),
    );
  }

  event$() {
    const { node } = getContext(this);
    const video = this.video;
    const events = ['ended', 'error', 'loadedmetadata', 'pause', 'play', 'progress', 'timeupdate'];
    function addHandler(key) {
      return function(handler) {
        video.addEventListener(key, handler);
      };
    }
    function removeHandler(key) {
      return function(handler) {
        video.removeEventListener(key, handler);
      };
    }
    return merge(...events.map(x => {
      return fromEventPattern(addHandler(x), removeHandler(x)).pipe(
        map(event => {
          return { type: x, data: event, uid: this.uid };
        })
      );
    })).pipe(
      tap(event => {
        // console.log('VimeoComponent.event', event);
        switch (event.type) {
          case 'loadedmetadata':
            this.duration = this.video.duration;
            this.checkMutedState();
            this.setActive();
            // console.log('PlayerComponent.onReady', this.videoSrc);
            break;
          case 'ended':
          case 'pause':
            this.playing = false;
            break;
          case 'play':
            this.playing = true;
            break;
          case 'progress':
            break;
          case 'timeupdate':
            // eslint-disable-next-line no-undef
            gsap.set(node, {
              '--percent': video.currentTime / video.duration,
            });
            break;
        }
      }),
    );
    /*
    bufferend
    bufferstart
    ended
    error
    loaded
    pause
    play
    playbackratechange
    progress
    seeked
    timeupdate
    volumechange
    */
  }

  checkMutedState() {
    const muted = SessionStorageService.get('muted');
    this.muted = this.opened ? Boolean(muted) : true;
  }

  setMuted(muted) {
    const video = this.video;
    if (video) {
      video.muted = muted;
      video.volume = (muted ? 0 : 0.5);
    }
  }

  setActive(ms = 2000) {
    this.active = true;
    const to = this.to_;
    if (to) {
      clearTimeout(to);
    }
    this.to_ = setTimeout(() => {
      this.active = false;
    }, ms);
  }

  setCurrentTime(currentTime) {
    const video = this.video;
    if (video) {
      video.currentTime = (currentTime);
    }
  }

  onDestroy() {
    if (this.video && typeof this.video.pause === 'function') {
      this.video.pause();
    }
  }

  play() {
    const video = this.video;
    if (video) {
      const promise = video.play();
      if (promise) {
        promise.then(() => {
          console.log('PlayerComponent.played');
        }).catch((error) => {
          console.log('PlayerComponent.played.error', error);
        });
      }
    }
  }

  pause() {
    const video = this.video;
    if (video) {
      const promise = video.pause();
      if (promise) {
        promise.then(() => {
          console.log('PlayerComponent.paused');
        }).catch((error) => {
          console.log('PlayerComponent.paused.error', error);
        });
      }
    }
  }

  togglePlay() {
    const video = this.video;
    if (video) {
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    }
  }

  fullscreen$() {
    const { node } = getContext(this);
    const buttonFullscreen = node.querySelector('.btn--fullscreen');
    return fromEvent(buttonFullscreen, 'click').pipe(
      tap(() => {
        /*
        if (this.fullscreen) {
          this.opened = false;
        }
        */
        this.toggleFullScreen();
      }),
    );
  }

  toggleFullScreen() {
    const { node } = getContext(this);
    const fullscreen = !this.fullscreen;
    if (fullscreen) {
      if (this.isIOS()) {
        this.iosFullscreen();
      } else if (node.requestFullscreen) {
        node.requestFullscreen();
      } else if (node.webkitRequestFullscreen) {
        node.webkitRequestFullscreen();
      } else if (node.msRequestFullscreen) {
        node.msRequestFullscreen();
      }
    } else {
      if (this.isIOS()) {
        this.iosExitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  isIOS() {
    return PlayerComponent.isIOS();
  }

  iosFullscreen() {
    const { node } = getContext(this);
    node.classList.toggle('fullscreen--ios', true);
    this.previousParent = node.parentNode;
    document.querySelector('body').appendChild(node);
    this.fullscreen = true;
  }

  iosExitFullscreen() {
    const { node } = getContext(this);
    this.previousParent.appendChild(node);
    node.classList.toggle('fullscreen--ios', false);
    this.fullscreen = false;
  }

  fullscreenChange$() {
    return fromEvent(document, 'fullscreenchange').pipe(
      tap(() => {
        const fullscreen = document.fullscreenElement != null;
        this.fullscreen = fullscreen;
      }),
    );
  }

  get opened() {
    return this.opened_;
  }

  set opened(opened) {
    if (this.opened_ !== opened) {
      this.opened_ = opened;
      const { node } = getContext(this);
      // node.classList.toggle('opened', opened);
      const body = document.querySelector('body');
      if (opened) {
        this.previousRect = node.getBoundingClientRect();
        this.previousRect = {
          left: this.previousRect.left + 'px',
          top: this.previousRect.top + 'px',
          width: this.previousRect.width + 'px',
          height: this.previousRect.height + 'px',
        };
        this.scrollPositionY = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        // console.log(node, this.previousRect, this.scrollPositionY);
        this.previousParentElement = node.parentElement;
        if (this.previousParentElement && this.previousParentElement.parentElement) {
          this.previousParentElement.parentElement.appendChild(node);
        }
        if (window.lenis) {
          window.lenis.stop();
        }
        body.classList.add('player--opened');
        node.classList.add('opened');
        this.muted = false;
        const onComplete = () => {
          gsap.set(node, { clearProps: true });
          this.setActive(4000);
        };
        if (this.previousRect) {
          gsap.fromTo(node, this.previousRect, {
            duration: 0.4,
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            ease: Power2.easeOut,
            onComplete: () => onComplete(),
          });
        }
      } else {
        this.muted = true;
        const onComplete = () => {
          gsap.set(node, { clearProps: true });
          if (this.previousParentElement) {
            this.previousParentElement.appendChild(node);
          }
          body.classList.remove('player--opened');
          node.classList.remove('opened');
          if (window.lenis) {
            window.lenis.start();
          }
        };
        if (this.previousRect) {
          gsap.fromTo(node, {
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
          }, {
            duration: 0.4,
            ...this.previousRect,
            ease: Power2.easeOut,
            onComplete: () => onComplete(),
          });
        } else {
          onComplete();
        }
        setTimeout(() => {
          window.scrollTo({
            top: this.scrollPositionY,
            left: 0,
            behavior: 'smooth'
          });
        }, 100);
      }
    }
  }

  get playing() {
    return this.playing_;
  }
  set playing(playing) {
    if (this.playing_ !== playing) {
      this.playing_ = playing;
      const { node } = getContext(this);
      playing ? node.classList.add('playing') : node.classList.remove('playing');
    }
  }

  get muted() {
    return this.muted_;
  }
  set muted(muted) {
    if (this.muted_ !== muted) {
      this.muted_ = muted;
      const { node } = getContext(this);
      muted ? node.classList.add('muted') : node.classList.remove('muted');
      this.setMuted(muted);
      SessionStorageService.set('muted', muted);
    }
  }

  get fullscreen() {
    return this.fullscreen_;
  }
  set fullscreen(fullscreen) {
    if (this.fullscreen_ !== fullscreen) {
      this.fullscreen_ = fullscreen;
      const { node } = getContext(this);
      fullscreen ? node.classList.add('fullscreen') : node.classList.remove('fullscreen');
    }
  }

  get active() {
    return this.active_;
  }
  set active(active) {
    if (this.active_ !== active) {
      this.active_ = active;
      const { node } = getContext(this);
      active ? node.classList.add('active') : node.classList.remove('active');
    }
  }

  static isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

}

PlayerComponent.meta = {
  selector: 'player',
  inputs: ['src', 'cover', 'labelPlay', 'labelClose'],
  template: /* html */`
    <video playsinline autoplay loop muted></video>
    <button type="button" class="btn--cta">
      <span class="label-play" [innerHTML]="labelPlay"></span>
      <span class="label-pause" [innerHTML]="labelClose"></span>
    </button>
    <button type="button" class="btn--close">
      <svg class="close"><use xlink:href="#close"></use></svg>
    </button>
    <div class="player__controls">
      <button type="button" class="btn--pause">
        <svg class="play"><use xlink:href="#play"></use></svg>
        <svg class="pause"><use xlink:href="#pause"></use></svg>
      </button>
      <button type="button" class="player__track">
        <div class="player__inner"></div>
      </button>
      <button type="button" class="btn--audio">
        <svg class="audio-on"><use xlink:href="#audio-on"></use></svg>
        <svg class="audio-off"><use xlink:href="#audio-off"></use></svg>
      </button>
      <button type="button" class="btn--fullscreen">
        <svg><use xlink:href="#fullscreen"></use></svg>
      </button>
    </div>
		`,
};
