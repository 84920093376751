/* global gsap, Power2 */
import { Directive, getContext } from 'rxcomp';
import { fromEvent } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export class AccordionDirective extends Directive {

  onInit() {
    const { node } = getContext(this);
    const items = this.items = Array.from(node.querySelectorAll(':scope > .accordion__item'));
    const titles = this.titles = items.map(x => x.querySelector(':scope > .accordion__title'));
    const contents = this.contents = items.map(x => x.querySelector(':scope > .accordion__content'));
    const states = this.states = items.map((x) => x.classList.contains('active'));
    states.forEach((state, index) => this.update(index, true));
    // console.log(states);
    fromEvent(titles, 'click').pipe(
      takeUntil(this.unsubscribe$),
      tap((event) => {
        const index = titles.indexOf(event.currentTarget);
        const state = states[index] = !states[index];
        this.update(index, state);
      }),
    ).subscribe();
  }

  update(index, immediate = false) {
    const items = this.items;
    const contents = this.contents;
    const states = this.states;
    const item = items[index];
    const state = states[index];
    item.classList.toggle('active', state);
    const content = contents[index];
    if (immediate) {
      if (state) {
        gsap.set(content, {
          height: 'auto',
          opacity: 1,
        });
      } else {
        gsap.set(content, {
          height: 0,
          opacity: 0,
        });
      }
      this.updateLenis();
    } else {
      if (state) {
        gsap.set(content, {
          height: 'auto'
        });
        const height = content.getBoundingClientRect().height;
        gsap.fromTo(content, {
          height: 0,
          opacity: 0
        }, {
          duration: 0.4,
          height: height,
          opacity: 1,
          ease: Power2.easeOut,
          onComplete: () => {
            gsap.set(content, {
              height: 'auto'
            });
            this.updateLenis();
          }
        });
      } else {
        const height = content.getBoundingClientRect().height;
        gsap.fromTo(content, {
          height: height,
          opacity: 1
        }, {
          duration: 0.4,
          height: 0,
          opacity: 0,
          ease: Power2.easeOut,
          onComplete: () => {
            this.updateLenis();
          }
        });
      }
    }
  }

  updateLenis() {
    if (window.lenis) {
      window.lenis.resize();
    }
  }

}

AccordionDirective.meta = {
  selector: '[accordion]',
};
