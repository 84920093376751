import { getContext } from 'rxcomp';
import { of } from 'rxjs';
import { ApiService } from '../../common/api/api.service';
import { environment } from '../../environment';

export class NewsService {

  static all$() {
    if (environment.flags.production) {
      return ApiService.get$('/news/all');
    } else {
      return ApiService.get$('/news/all.json');
    }
  }

  static filters$() {
    if (environment.flags.production) {
      return ApiService.get$('/news/filters');
    } else {
      return ApiService.get$('/news/filters.json');
    }
  }

  static getItems(instance) {
    const { node } = getContext(instance);
    const evidenceNodesUrls = Array.from(node.querySelectorAll('.news__evidence>.card--news')).map(x => x.getAttribute('href'));
    const itemNodes = Array.from(node.querySelectorAll('.listing--news--static>.listing--news__item'));
    const items = itemNodes.map(itemNode => {
      const img = itemNode.querySelector('img');
      const categoryId = parseInt(itemNode.querySelector('a').getAttribute('data-category'));
      const url = itemNode.querySelector('a').getAttribute('href');
      const evidence = evidenceNodesUrls.includes(url);
      const item = {
        url,
        image: img ? img.getAttribute('src') : null,
        title: itemNode.querySelector('.card--news__title').innerText,
        abstract: itemNode.querySelector('.card--news__abstract').innerText,
        date: itemNode.querySelector('.card--news__date').innerText,
        category: {
          id: categoryId,
          name: itemNode.querySelector('.card--news__category').innerText,
        },
        categories: [categoryId],
        evidence,
      };
      return item;
    });
    return of(items);
  }

  static getFilters(instance) {
    const { node } = getContext(instance);
    const filterNodes = Array.from(node.querySelectorAll('.nav--filter>.nav__item'));
    const filters = filterNodes.map(filterNode => {
      const filter = {
        value: filterNode.getAttribute('data-id'),
        label: filterNode.querySelector('span').innerText,
      };
      return filter;
    });
    return of({ category: { mode: 'select', options: filters }, search: { mode: 'query' } });
  }
}
