import { ControlComponent } from './control.component';

export class ControlTextareaComponent extends ControlComponent {

  onInit() {
    super.onInit();
    this.disabled = this.disabled || false;
    this.placeholder = this.placeholder || this.label;
  }

}

ControlTextareaComponent.meta = {
  selector: '[control-textarea]',
  inputs: ['control', 'label', 'placeholder', 'disabled'],
  template: /* html */ `
		<div class="group--form--textarea" [class]="{ required: control.invalid }">
			<label [labelFor]="uniqueId" *if="label"><span [innerHTML]="label"></span> <span class="required__sign">*</span></label>
			<textarea [id]="uniqueId" [name]="control.name" class="control--text" [formControl]="control" [placeholder]="placeholder" [innerHTML]="label" rows="4" [disabled]="disabled"></textarea>
			<span class="required__badge" [innerHTML]="'required' | label"></span>
		</div>
		<errors-component [control]="control"></errors-component>
	`
};
