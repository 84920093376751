import Lenis from '@studio-freight/lenis';
import { Directive } from 'rxcomp';

const FORCE_DISABLED = false;

export class LenisDirective extends Directive {

  onInit() {
    addEventListener('DOMContentLoaded', () => {
      this.init();
    });
  }

  init() {
    if (!FORCE_DISABLED) {
      const lenis = new Lenis({
        smoothWheel: true,
        smoothTouch: false,
        /*
        orientation: 'vertical',
        gestureOrientation: 'vertical',
        wheelMultiplier: 1,
        touchMultiplier: 2,
        infinite: false,
        duration: 1.2,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        */
      });

      const raf = (time) => {
        lenis.raf(time);
        requestAnimationFrame(raf);
      };

      requestAnimationFrame(raf);

      let scrolled_;
      lenis.on('scroll', ({ scroll }) => {
        const scrolled = scroll > 120;
        if (scrolled_ !== scrolled) {
          scrolled_ = scrolled;
          document.documentElement.classList.toggle('lenis-scrolled', scrolled);
        }
      });

      // fix lenis with lazy loaded images that have no aspect-ratio.
      Array.from(document.querySelectorAll('img')).forEach(img => {
        img.addEventListener('load', (event) => {
          // console.log('img', event);
          lenis.resize();
        });
      });

      window.lenis = lenis;
      this.lenis = lenis;
    } else {
      this.lenis = undefined;
    }
  }

}

LenisDirective.meta = {
  selector: '[lenis]',
};
