import { atcb_action } from 'add-to-calendar-button';
import { Directive, getContext } from 'rxcomp';
import { fromEvent } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../environment';

export class CalendarBtnDirective extends Directive {

  onInit() {
    if (!this.item) {
      return;
    }
    // this.initializeSR(this.item);
    this.click$(this.item).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  click$(item) {
    const date = new Date(item.date);
    const startDate = `${date.getFullYear()}-${padded(date.getMonth() + 1)}-${padded(date.getDate())}`;
    const startTime = `${padded(date.getHours())}:${padded(date.getMinutes())}`;
    const endTime = `${padded(date.getHours())}:${padded(date.getMinutes())}`;
    const config = {
      name: item.title,
      description: item.abstract,
      startDate,
      startTime,
      endTime,
      options: ['Apple', 'Google', 'iCal', 'Outlook.com'],
      language: environment.currentLanguage,
      timeZone: 'Europe/Rome',
      location: 'World Wide Web',
    };
    const { node } = getContext(this);
    return fromEvent(node, 'click').pipe(
      tap(_ => {
        atcb_action(config, node);
      })
    );
  }

  initializeSR(item) {
    const date = new Date(item.date);
    const startDate = `${date.getFullYear()}-${padded(date.getMonth() + 1)}-${padded(date.getDate())}`;
    const startTime = `${padded(date.getHours())}:${padded(date.getMinutes())}`;
    const endTime = `${padded(date.getHours())}:${padded(date.getMinutes())}`;
    console.log(startDate, startTime, endTime);
    const { node } = getContext(this);
    const label = node.innerText;
    node.innerHTML = /* html */`
    <add-to-calendar-button
  language="${environment.currentLanguage}"
  name="${item.title}"
  description="Play with me!"
  startDate="${startDate}"
  startTime="${startTime}"
  endTime="${endTime}"
  timeZone="Europe/Rome"
  location="World Wide Web"
  options="'Apple','Google','iCal','Outlook.com'"
  buttonStyle="custom"
  customCss="../css/calendar-btn.css"
  trigger="click"
  hideIconButton
  size="1"
  label="${label}"
  lightMode="bodyScheme"
></add-to-calendar-button>`;
  }

}

CalendarBtnDirective.meta = {
  selector: '[calendar-btn]',
  inputs: ['item']
};

function padded(num, size = 2) {
  var s = '000000000' + num;
  return s.substr(s.length - size);
}
