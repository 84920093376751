import { getContext } from 'rxcomp';
import { fromEvent } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SwiperDirective } from '../../common/swiper/swiper.directive';

export class SwiperGalleryDirective extends SwiperDirective {

  onInit() {
    this.options = {
      slidesPerView: 1,
      spaceBetween: 24,
      /*
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 24
        },
        1440: {
          slidesPerView: 1,
          spaceBetween: 24
        },
        1920: {
          slidesPerView: 1,
          spaceBetween: 24
        }
      },
      */
      speed: 400,
      centeredSlides: false,
      loop: false,
      loopAdditionalSlides: 100,
      keyboardControl: true,
      mousewheelControl: false,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
    };
    const { node } = getContext(this);
    const target = node.querySelector('.swiper-container');
    this.init_(target);
    const togglers = node.querySelectorAll('.btn--gallery, .btn--close');
    fromEvent(togglers, 'click').pipe(
      tap(() => this.expanded = !this.expanded),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  get expanded() {
    return this.expanded_;
  }

  set expanded(expanded) {
    if (this.expanded_ !== expanded) {
      this.expanded_ = expanded;
      const { node } = getContext(this);
      // node.classList.toggle('expanded', expanded);
      if (expanded) {
        this.scrollPositionY = window.pageYOffset;
        console.log(this.scrollPositionY);
        node.classList.add('expanded');
      } else {
        node.classList.remove('expanded');
        setTimeout(() => {
          window.scrollTo({
            top: this.scrollPositionY,
            left: 0,
            behavior: 'smooth'
          });
          // console.log(window.pageYOffset);
        }, 100);
      }
    }
  }

}

SwiperGalleryDirective.meta = {
  selector: '[swiper-gallery]'
};
