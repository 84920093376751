import { Pipe } from 'rxcomp';
import { environment } from '../../environment';
import { Utils } from '../utils/utils';

export class LabelPipe extends Pipe {

  static transform(key) {
    const labels = LabelPipe.labels_;
    return labels[key] || key; // `#${key}#`;
  }

  static getKeys(...keys) {
    return LabelPipe.transform(keys.map(x => x.replace('-', '_')).join('_'));
  }

  static setLabels() {
    const LABELS = Utils.merge({
      select: 'Seleziona',
      error_email: 'Email non valida',
      error_phoneNumber: 'Inserire un numero di telefono nel formato +39-xxx-xxxxxxx',
      error_match: 'I campi non corrispondono',
      error_required: 'Campo obbligatorio',
    }, environment.labels);
    this.labels_ = LABELS;
  }
}

LabelPipe.setLabels();

LabelPipe.meta = {
  name: 'label',
};
