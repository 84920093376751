import { BehaviorSubject, EMPTY, fromEvent, merge, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export const CursorType = {
  idle: 'idle',
  nav: 'nav',
  text: 'text',
  drag: 'drag',
  expand: 'expand',
  blank: 'blank',
  close: 'close',
  add: 'add',
  remove: 'remove',
  prev: 'prev',
  next: 'next',
  play: 'play',
  audioOn: 'audio-on',
  audioOff: 'audio-off',
};

export class CursorService {

  static cursors = Object.keys(CursorType).map(key => CursorType[key]);
  static update$ = new Subject();
  static cursor$ = new BehaviorSubject(CursorType.idle);
  static title$ = new BehaviorSubject('');

  static registerNode$(node) {
    let cursorType = node.getAttribute('cursor-type');
    if (this.cursors.indexOf(cursorType) !== -1) {
      return merge(fromEvent(node, 'mouseenter'), fromEvent(node, 'mouseleave'), CursorService.update$.pipe(
        filter((x) => x === node),
        map(() => ({ type: 'mouseenter' })),
      )).pipe(
        tap(event => {
          cursorType = node.getAttribute('cursor-type');
          if (event.type === 'mouseenter') {
            this.cursor$.next(cursorType);
            this.title$.next(node.getAttribute('title'));
          } else {
            this.clear();
          }
        }),
      );
    } else {
      return EMPTY;
    }
  }

  static clear() {
    // console.log('CursorService.clear');
    this.cursor$.next(CursorType.idle);
    this.title$.next('');
  }

}
