import { getContext } from 'rxcomp';
import { of } from 'rxjs';
import { ApiService } from '../../common/api/api.service';
import { environment } from '../../environment';

export class BrandService {

  static all$() {
    if (environment.flags.production) {
      return ApiService.get$('/brand/all');
    } else {
      return ApiService.get$('/brand/all.json');
    }
  }

  static filters$() {
    if (environment.flags.production) {
      return ApiService.get$('/brand/filters');
    } else {
      return ApiService.get$('/brand/filters.json');
    }
  }

  static getItems(instance) {
    const { node } = getContext(instance);
    const itemNodes = Array.from(node.querySelectorAll('.listing--brand--static>.listing--brand__item'));
    const items = itemNodes.map(itemNode => {
      const categoryId = parseInt(itemNode.querySelector('a').getAttribute('data-category'));
      const brands = Array.from(itemNode.querySelectorAll('.card--brand__brand')).map(brandNode => ({
        image: brandNode.getAttribute('src'),
        title: brandNode.getAttribute('alt'),
      }));
      const item = {
        url: itemNode.querySelector('a').getAttribute('href'),
        image: itemNode.querySelector('img').getAttribute('src'),
        title: itemNode.querySelector('.card--brand__title').innerText,
        category: {
          id: categoryId,
          key: itemNode.querySelector('.card--brand__category svg').getAttribute('class'),
          name: itemNode.querySelector('.card--brand__category span').innerText,
        },
        categories: [categoryId],
        brands,
      };
      return item;
    });
    return of(items);
  }

  static getFilters(instance) {
    const { node } = getContext(instance);
    const filterNodes = Array.from(node.querySelectorAll('.nav--filter>.nav__item'));
    const filters = filterNodes.map(filterNode => {
      const filter = {
        value: filterNode.getAttribute('data-id'),
        label: filterNode.querySelector('span').innerText,
      };
      return filter;
    });
    return of({ category: { mode: 'select', options: filters }, search: { mode: 'query' } });
  }
}
