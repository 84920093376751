import { Component, getContext } from 'rxcomp';
import { fromEvent, range } from 'rxjs';
import { animationFrame } from 'rxjs/internal/scheduler/animationFrame';
import { distinctUntilChanged, filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { CursorService, CursorType } from './cursor.service';

export class CursorComponent extends Component {

  get active() {
    return this.active_;
  }

  set active(active) {
    if (this.active_ !== active) {
      this.active_ = active;
      const html = document.querySelector('html');
      (this.active_ && this.cursorType_ !== CursorType.idle) ? html.classList.add('cursor--active') : html.classList.remove('cursor--active');
    }
  }

  get cursorType() {
    return this.cursorType_;
  }

  set cursorType(cursorType) {
    if (this.cursorType_ !== cursorType) {
      this.cursorType_ = cursorType;
      const { node } = getContext(this);
      switch (cursorType) {
        case CursorType.nav:
          node.setAttribute('class', 'over');
          break;
        case CursorType.text:
        case CursorType.drag:
        case CursorType.expand:
        case CursorType.blank:
        case CursorType.close:
        case CursorType.add:
        case CursorType.remove:
        case CursorType.prev:
        case CursorType.next:
        case CursorType.play:
        case CursorType.audioOn:
        case CursorType.audioOff:
          node.setAttribute('class', `over ${cursorType}`);
          break;
        default:
          node.setAttribute('class', '');
      }
      const html = document.querySelector('html');
      (this.active_ && this.cursorType_ !== CursorType.idle) ? html.classList.add('cursor--active') : html.classList.remove('cursor--active');
    }
  }

  get title() {
    return this.title_;
  }

  set title(title) {
    if (this.title_ !== title) {
      this.title_ = title;
      const { node } = getContext(this);
      const cursorTitle = node.querySelector('.cursor__title');
      cursorTitle.innerHTML = title;
    }
  }

  onInit() {
    // console.log('CursorComponent.onInit');

    const { node } = getContext(this);

    const cursors = CursorService.cursors;

    node.innerHTML = `
			<div class="cursor__circle">
				${cursors.map(x => `<svg class="cursor__${x}"><use xlink:href="#cursor__${x}"></use></svg>`).join('')}
        <div class="cursor__title"></div>
			</div>
		`;

    this.mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    this.over = false;

    setTimeout(() => {
      this.initObservers();
    }, 10);
  }

  initObservers() {
    // position
    this.move$().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe();

    CursorService.cursor$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cursorType => this.cursorType = cursorType);

    CursorService.title$.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    ).subscribe(title => this.title = title);

    // animation
    this.render$().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe();

    // resize
    this.resize$().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  over$(nodes) {
    const { node } = getContext(this);
    return fromEvent(nodes, 'mouseenter').pipe(
      tap(() => node.classList.add('over')),
    );
  }

  out$(nodes) {
    const { node } = getContext(this);
    return fromEvent(nodes, 'mouseleave').pipe(
      tap(() => node.classList.remove('over')),
    );
  }

  render$() {
    const { node } = getContext(this);
    const mouse = this.mouse;
    const raf$ = range(0, Number.POSITIVE_INFINITY, animationFrame);
    // return animationFrames().pipe(
    return raf$.pipe(
      filter(() => this.active === true && this.position != null),
      tap(() => {
        const position = this.position;
        position.x += (mouse.x - position.x) / 4;
        position.y += (mouse.y - position.y) / 4;
        // position.x = mouse.x;
        // position.y = mouse.y;
        position.o += (1 - position.o) / 10;
        gsap.set(node, {
          x: position.x,
          y: position.y,
          opacity: position.o,
        });
      }),
    );
  }

  move$() {
    const mouse = this.mouse;
    return fromEvent(window, 'mousemove').pipe(
      tap(event => {
        const x = event.clientX;
        const y = event.clientY;
        mouse.x = x;
        mouse.y = y;
        if (!this.position) {
          this.position = Object.assign({}, mouse);
          this.position.o = 0;
        }
      })
    );
  }

  resize$() {
    return fromEvent(window, 'resize').pipe(
      startWith(null),
      tap(() => this.active = window.innerWidth >= 1024),
    );
  }

}

CursorComponent.meta = {
  selector: '[cursor]',
};
