import { getContext } from 'rxcomp';
import { SwiperDirective } from '../../common/swiper/swiper.directive';

export class SwiperGovernanceRelatedDirective extends SwiperDirective {

  onInit() {
    this.options = {
      slidesPerView: 'auto',
      spaceBetween: 24,
      /*
      breakpoints: {
        768: {
          slidesPerView: 'auto',
          spaceBetween: 24,
        },
        1024: {
          slidesPerView: 'auto',
          spaceBetween: 24
        },
        1440: {
          slidesPerView: 'auto',
          spaceBetween: 24
        },
        1920: {
          slidesPerView: 'auto',
          spaceBetween: 24
        }
      },
      */
      speed: 600,
      centeredSlides: false,
      loop: false,
      loopAdditionalSlides: 100,
      keyboardControl: true,
      mousewheelControl: false,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
    };
    const { node } = getContext(this);
    const target = node.querySelector('.swiper-container');
    this.init_(target);
  }

}

SwiperGovernanceRelatedDirective.meta = {
  selector: '[swiper-governance-related]'
};
