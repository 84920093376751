import { FormValidator } from 'rxcomp-form';

export default function RequiredIPOFieldValidator(fieldName, formGroup) {

  return new FormValidator(function(value) {
    let field = null;
		if (typeof formGroup === 'function') {
			field = formGroup().get(fieldName);
		} else if (formGroup) {
			field = formGroup.get(fieldName);
		}
    if (!value || !field) {
      return null;
    }
    let forbidden_countries = [
      "United States",
      "Canada",
      "Japan",
      "Australia",
      "South Africa"
    ];
    // console.log("field IPO", field);
    if(field.name == "res_country") {
    return forbidden_countries.includes(value) ? { res_country: true } : null;
    } else if(field.name == "dom_country") {
      return forbidden_countries.includes(value) ? { dom_country: true } : null;
    } else if(field.name == "area_code") {
      return forbidden_countries.includes(value) ? { area_code: true } : null;
    }
  });
}
