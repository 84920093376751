import { CoreModule, Module } from 'rxcomp';
import { FormModule } from 'rxcomp-form';
import AppComponent from './app.component';
import { CommonModule } from './common/common.module';
import { ComponentsModule } from './components/components.module';
import { ControlsModule } from './controls/controls.module';
import './polyfills/scroll-timeline';

export default class AppModule extends Module { }

AppModule.meta = {
  imports: [
    CoreModule,
    FormModule,
    CommonModule,
    ControlsModule,
    ComponentsModule,
  ],
  declarations: [
  ],
  bootstrap: AppComponent,
};
