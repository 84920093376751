import { Component, getContext } from 'rxcomp';

export class ReelDirective extends Component {

  get index() {
    return this.index_;
  }

  set index(index) {
    if (this.index_ !== index) {
      this.index_ = index;
      this.items.forEach((item, i) => {
        item.classList.toggle('active', i === index);
      });
    }
  }

  onInit() {
    const { node } = getContext(this);
    const items = this.items = Array.from(node.children);
    this.duration = this.duration || 4;
    this.index = this.index || 0;
    this.play();
  }

  next() {
    this.index = (this.index + 1) % this.items.length;
    this.play();
  }

  play() {
    this.to = setTimeout(() => {
      this.next();
    }, this.duration * 1000);
  }

  pause() {
    if (this.to) {
      clearTimeout(this.to);
    }
  }

  onDestroy() {
    this.pause();
  }

}

ReelDirective.meta = {
  selector: '[reel]',
  inputs: ['duration', 'index']
};
