import { ControlComponent } from './control.component';

export class ControlTextComponent extends ControlComponent {

  onInit() {
    super.onInit();
    this.disabled = this.disabled || false;
    this.placeholder = this.placeholder || this.label;
  }

}

ControlTextComponent.meta = {
  selector: '[control-text]',
  inputs: ['control', 'label', 'placeholder', 'disabled'],
  template: /* html */ `
		<div class="group--form" [class]="{ required: control.invalid }">
			<label [labelFor]="uniqueId" *if="label">
        <span [innerHTML]="label"></span> <span class="required__sign">*</span>
      </label>
			<span class="required__badge" [innerHTML]="'required' | label"></span>
			<input [id]="uniqueId" [name]="control.name" type="text" class="control--text" [formControl]="control" [placeholder]="placeholder" [disabled]="disabled" />
		</div>
		<errors-component [control]="control"></errors-component>
	`
};
