import { ApiService } from '../../common/api/api.service';
import { environment } from '../../environment';

export class ContactService {

  static data$() {
    if (environment.flags.production) {
      return ApiService.get$('/wp-json/dexelance/v1/frmoptions/' + window.post_id);
    } else {
      return ApiService.get$('/contact/data.json');
    }
  }

  static submit$(payload) {
    if (environment.flags.production) {
      return ApiService.http$('POST', window.ajaxurl, payload, 'application/x-www-form-urlencoded');
    } else {
      return ApiService.get$('/contact/submit.json');
    }
  }

}
