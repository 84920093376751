import { Component } from 'rxcomp';
import { FormControl, FormGroup } from 'rxcomp-form';
import { combineLatest, of } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { FilterMode } from '../../common/filter/filter-item';
import { FilterService } from '../../common/filter/filter.service';
import { LocationService } from '../../common/location/location.service';

export const PAGE_SIZE = 12;

export class FiltersComponent extends Component {

  load$() {
    return combineLatest([
      of([]),
      of({ search: { mode: 'query' } }),
    ]);
  }

  setFiltersParams() {
    // nope;
  }

  doFilterItem(key, item, value) {
    switch (key) {
      case 'search':
        return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          // item.address.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          (item.city && item.city.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
          (item.countries && item.countries.find(x => x.label.toLowerCase().indexOf(value.toLowerCase()) !== -1));
      default:
        return false;
    }
  }

  onInit() {
    this.pageSize = PAGE_SIZE;
    const pagination = LocationService.deserialize('pagination') || {
      total: 1,
      page: 1,
      pages: [1],
    };
    this.initialPagination = { ...pagination };
    this.pagination = pagination;
    // console.log('onInit', pagination, this.initialPagination);
    this.items = [];
    this.filteredItems = [];
    this.visibleItems = [];
    this.filters = {};
    const form = this.form = new FormGroup({
      search: new FormControl(null),
    });
    this.controls = form.controls;
    form.changes$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      // console.log('FiltersComponent.onInit.form.changes$', form.value.search);
      this.setFilterByKeyAndValue('search', form.value.search);
      this.pushChanges();
    });
    this.ready = false;
    this.busy = true;
    this.load$().pipe(
      first(),
      finalize(() => {
        this.busy = false;
        this.pushChanges();
      }),
    ).subscribe(data => {
      this.items = data[0];
      this.filters = data[1];
      this.onLoad();
      this.ready = true;
    });
  }

  onLoad() {
    const items = this.items;
    const filters = this.filters || {};
    Object.keys(filters).forEach(key => {
      filters[key].mode = filters[key].mode || FilterMode.OR;
    });
    const initialParams = {};
    const filterService = new FilterService(filters, initialParams, (key, filter) => {
      switch (key) {
        default:
          filter.filter = (item, value) => {
            return this.doFilterItem(key, item, value);
          };
      }
    });
    this.filterService = filterService;
    this.filters = filterService.filters;
    this.setFiltersParams();
    const search = (this.filters.search && this.filters.search.values.length) ? this.filters.search.values[0] : null;
    this.form.patch({ search });
    filterService.items$(items).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(filteredItems => {
      this.filteredItems = this.doSortItems(filteredItems);
      this.visibleItems = this.getVisibleItems();
      this.pushChanges();
      /*
      LocomotiveScrollService.update();
      LocomotiveScrollService.start();
      */
      // console.log('FiltersComponent.filteredItems', filteredItems.length);
    });
    this.onLoaded();
  }

  onLoaded() {
    // console.log('FiltersComponent.onLoaded');
  }

  doSortItems(items) {
    return items;
  }

  getVisibleItems() {
    const filteredItems = this.filteredItems;
    const pagination = this.pagination;
    pagination.total = Math.ceil(filteredItems.length / this.pageSize);
    pagination.page = 1;
    if (this.initialPagination) {
      pagination.page = this.initialPagination.page;
      this.initialPagination = null;
    }
    pagination.pages = new Array(pagination.total).fill(0).map((x, i) => i + 1);
    const from = (pagination.page - 1) * this.pageSize;
    const to = from + Math.min(this.pageSize, filteredItems.length - from);
    const visibleItems = filteredItems.slice(from, to);
    // console.log('visibleItems', pagination.page);
    return visibleItems;
  }

  hasNext() {
    return this.pagination.page < this.pagination.total;
  }

  hasPrev() {
    return this.pagination.page > 1;
  }

  setPage(page) {
    const pagination = this.pagination;
    if (page !== pagination.page && page > 0 && page <= pagination.total) {
      this.pagination.page = page;
      const filteredItems = this.filteredItems;
      const from = (page - 1) * this.pageSize;
      const to = from + Math.min(this.pageSize, filteredItems.length - from);
      const visibleItems = filteredItems.slice(from, to);
      this.visibleItems = visibleItems;
      LocationService.serialize('pagination', pagination);
      this.pushChanges();
    }
  }

  showMore() {
    if (this.visibleItems.length + this.pageSize >= this.filteredItems.length) {
      this.visibleItems = this.filteredItems.slice();
    } else {
      this.visibleItems = this.filteredItems.slice(0, Math.min(this.visibleItems.length + this.pageSize, this.filteredItems.length));
    }
    this.pushChanges();
    /*
    LocomotiveScrollService.update();
    */
  }

  onSearch() {
    // console.log('FiltersComponent.onSearch', this.form.value);
    /*
    this.setFilterByKeyAndValue('country', this.form.value.country);
    */
    this.setFilterByKeyAndValue('search', this.form.value.search);
    this.pushChanges();
  }

  setFilterByKeyAndValue(key, value) {
    const filter = this.filters[key];
    if (filter) {
      if (filter.mode === FilterMode.QUERY) {
        filter.set(value);
      } else {
        const option = filter.options.find(x => x.value === value);
        // console.log(filter.options, option);
        if (option) {
          filter.set(option);
        } else {
          filter.clear();
        }
      }
    }
  }

  onFilterDidChange() {
    this.pushChanges();
  }

}

FiltersComponent.meta = {
  selector: '[filters]',
};
