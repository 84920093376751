import { getContext, getContextByNode } from 'rxcomp';
import { SwiperDirective } from '../../common/swiper/swiper.directive';

export class SwiperHistoryDirective extends SwiperDirective {

  onInit() {
    this.options = {
      slidesPerView: 1,
      spaceBetween: 48,
      /*
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 24
        },
        1440: {
          slidesPerView: 1,
          spaceBetween: 24
        },
        1920: {
          slidesPerView: 1,
          spaceBetween: 24
        }
      },
      */
      speed: 600,
      centeredSlides: true,
      loop: false,
      loopAdditionalSlides: 100,
      keyboardControl: true,
      mousewheelControl: false,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
    };
    const { node } = getContext(this);
    const target = node.querySelector('.swiper-container');
    this.init_(target);
  }

  onSwiper(swiper) {
    const swiperNode = document.querySelector('[swiper-timeline]');
    const context = getContextByNode(swiperNode);
    if (context && context.instance && context.instance.swiper) {
      context.instance.swiper.controller.control = swiper;
      swiper.controller.control = context.instance.swiper;
    }
  }

}

SwiperHistoryDirective.meta = {
  selector: '[swiper-history]'
};
