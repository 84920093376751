import { BehaviorSubject } from 'rxjs';

export class HeaderService {

  static header$_ = new BehaviorSubject();

  static get currentHeader() {
    return this.header$_.getValue();
  }

  static setHeader(id) {
    this.header$_.next(id);
  }

  static toggleHeader(id) {
    this.header$_.next(this.currentHeader === id ? undefined : id);
  }

  static onBack() {
    this.header$_.next(undefined);
  }

  static header$() {
    return this.header$_;
  }

}
