import { Component } from 'rxcomp';
import { FormControl, FormGroup, Validators } from 'rxcomp-form';
import { first, takeUntil, tap } from 'rxjs/operators';
import { GtmService } from '../../common/gtm/gtm.service';
import { FormService } from '../../controls/form.service';
import RequiredIfValidator from '../../controls/required-if.validator';
import { ContactService } from './contact.service';

export class ContactComponent extends Component {

  onInit() {
    this.error = null;
    this.success = false;
    this.response = null;
    this.message = null;
    const isInterestedInApplication = RequiredIfValidator(() => Boolean(this.form.value.occupation === 'interessato_alla_candidatura'));
    const form = this.form = new FormGroup({
      company: new FormControl(null),
      firstName: new FormControl(null, [Validators.RequiredValidator()]),
      lastName: new FormControl(null, [Validators.RequiredValidator()]),
      email: new FormControl(null, [Validators.RequiredValidator(), Validators.EmailValidator()]),
      country: new FormControl(null),
      occupation: new FormControl(null),
      cv: new FormControl(null, [isInterestedInApplication]),
      message: new FormControl(null),
      privacy: new FormControl(null, [Validators.RequiredTrueValidator()]),
      checkRequest: window.antiforgery,
      checkField: '',
      action: 'save_contact',
    });
    this.controls = form.controls;
    form.changes$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.pushChanges();
    });
    this.load$().pipe(
      first(),
    ).subscribe();
  }

  load$() {
    return ContactService.data$().pipe(
      tap(data => {
        const controls = this.controls;
        controls.country.options = FormService.toSelectOptions(data.country.options);
        controls.occupation.options = FormService.toSelectOptions(data.occupation.options);
        this.pushChanges();
      })
    );
  }

  test() {
    const form = this.form;
    // const controls = this.controls;
    // const country = controls.country.options.length > 1 ? controls.country.options[1].id : null;
    // const occupation = controls.occupation.options.length > 1 ? controls.occupation.options[1].id : null;
    form.patch({
      firstName: 'Jhon',
      lastName: 'Appleseed',
      email: 'jhonappleseed@gmail.com',
      // country: country,
      // occupation: occupation,
      message: 'Hi!',
      privacy: true,
      checkRequest: window.antiforgery,
      checkField: ''
    });
  }

  reset() {
    const form = this.form;
    form.reset();
  }

  onSubmit() {
    const form = this.form;
    // console.log('ContactComponent.onSubmit', form.value);
    // console.log('ContactComponent.onSubmit', 'form.valid', valid);
    if (form.valid) {
      // console.log('ContactComponent.onSubmit', form.value);
      form.submitted = true;
      ContactService.submit$(form.value).pipe(
        first(),
      ).subscribe(_ => {
        if (_.success) {
          GtmService.push({ 'event': 'Contact', 'form_name': 'Contatti' });
        }
        this.success = true;
        form.reset();
        this.response = _.data['response'];
        this.message = _.data['message'];
        if (form.value.newsletter) {
          GtmService.push({
            'event': 'ContactNewsletter',
            'form_name': 'ContattiNewsletter'
          });
        }
      }, error => {
        console.log('ContactComponent.error', error);
        this.error = error;
        this.pushChanges();
      });
    } else {
      form.touched = true;
    }
  }

  onOccupationDidChange() {
    console.log('ContactComponent.onOccupationDidChange');
    this.controls.cv.switchValidators_();
  }

}

ContactComponent.meta = {
  selector: '[contact]',
};
