/* global post_id */
import { ApiService } from '../../common/api/api.service';
import { environment } from '../../environment';

export class IRCalendarService {

  static all$() {
    if (environment.flags.production) {
      return ApiService.get$('/wp-json/dexelance/v1/calendar/' + post_id);
    } else {
      return ApiService.get$('/ir-calendar/all.json');
    }
  }

}
