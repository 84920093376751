import { getContext } from 'rxcomp';
import { ControlComponent } from './control.component';

export class ControlSearchComponent extends ControlComponent {

  onInit() {
    super.onInit();
    this.disabled = this.disabled || false;
    this.placeholder = this.placeholder || this.label;
  }

  onFocus() {
    const { node } = getContext(this);
    const input = node.querySelector('.control--text');
    if (input) {
      input.setSelectionRange(0, 1000);
      input.focus();
    }
  }

}

ControlSearchComponent.meta = {
  selector: '[control-search]',
  inputs: ['control', 'label', 'placeholder', 'disabled'],
  template: /* html */ `
		<div class="group--form" [class]="{ required: control.invalid }">
			<svg class="search" (click)="onFocus($event)"><use xlink:href="#search"></use></svg>
			<input type="text" class="control--text" [formControl]="control" [placeholder]="placeholder" [disabled]="disabled" />
		</div>
	`
};
