import { Directive, getContext } from 'rxcomp';
import { fromEvent, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export class DataImageDirective extends Directive {

  onInit() {
    const { node } = getContext(this);
    const selector = node.getAttribute('data-target');
    const target = document.querySelector(selector);
    this.initialSrc = target.getAttribute('src');
    this.listeners$().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  listeners$() {
    return merge(
      this.over$(),
      this.out$(),
    );
  }

  over$() {
    const { node } = getContext(this);
    return fromEvent(node, 'mouseenter').pipe(
      tap(() => {
        node.classList.add('over');
        const src = node.getAttribute('data-image');
        const selector = node.getAttribute('data-target');
        const target = document.querySelector(selector);
        target.setAttribute('src', src);
        // console.log(target);
      }),
    );
  }

  out$() {
    const { node } = getContext(this);
    const selector = node.getAttribute('data-container');
    const target = (selector && document.querySelector(selector)) || node;
    return fromEvent(target, 'mouseleave').pipe(
      tap(() => {
        node.classList.remove('over');
        const selector = node.getAttribute('data-target');
        const target = document.querySelector(selector);
        target.setAttribute('src', this.initialSrc || '');
      }),
    );
  }

}

DataImageDirective.meta = {
  selector: '[data-image]',
};
